<template>
  <Layout>
    <div class="app-search-list">
      <a-page-header
        title="搜一搜"
        sub-title="提交页面到微信搜索，增加外部自然流量"
      />

      <div class="tool">
        <a-form layout="inline">
          <a-button
            v-if="hasPermission"
            :disabled="selectedRowKeys.length > 0 ? false : true"
            @click="add"
            type="primary"
          >
            <a-icon type="upload" /> 提交收录
          </a-button>
          <a-button v-else @click="auth" type="primary">
            <a-icon type="scan" /> 扫码授权
          </a-button>
        </a-form>

        <a-form layout="inline" :form="form" @submit="handleSearch">
          <a-form-item label="页面类型">
            <a-select
              allow-clear
              style="min-width:100px"
              placeholder="请选择"
              v-decorator="['type']"
            >
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option :value="1">商品</a-select-option>
              <a-select-option :value="2">分类</a-select-option>
              <a-select-option :value="3">普通</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="提交状态">
            <a-select
              allow-clear
              style="min-width:100px"
              placeholder="请选择"
              v-decorator="['status']"
            >
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option :value="1">未提交</a-select-option>
              <a-select-option :value="2">已提交</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item>
            <a-button type="primary" html-type="submit">
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="table-wrap">
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange
          }"
          :columns="columns"
          :data-source="list"
          :loading="loading"
        >
        </a-table>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import { mapState } from "vuex";

const SearchSvc = require("@/service/search");

export default {
  name: "SearchList",

  data() {
    return {
      form: this.$form.createForm(this, { name: "form_search" }),

      list: [],
      filters: { type: 0, status: 0 },
      loading: false,

      selectedRowKeys: []
    };
  },

  computed: {
    ...mapState({
      func: state => state.func
    }),
    hasPermission() {
      return this.func.includes(57); // URL Schema
    },

    columns() {
      return [
        {
          title: "类型",
          dataIndex: "typeText",
          key: "typeText",
          width: 60,
          align: "center"
        },
        {
          title: "标题",
          dataIndex: "title",
          key: "title"
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: 80,
          customRender: status => {
            return status ? "已提交" : "未提交";
          }
        }
      ];
    }
  },

  mixins: [],

  methods: {
    async auth() {
      this.$root.$emit("auth");
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    async add() {
      if (this.selectedRowKeys.length <= 0) {
        return;
      }

      const ids = this.selectedRowKeys
        .map(idx => this.list[idx])
        .map(x => ({ type: x.type, pid: x.pid }));
      const json = JSON.stringify(ids);

      try {
        await SearchSvc.create({ json });

        this.selectedRowKeys = [];
        this.$message.info("提交成功");

        await this.fetch();
      } catch (err) {
        console.error(err);
        this.$message.error("提交失败，错误：" + err.message);
      }
    },

    async handleSearch(e) {
      e.preventDefault();
      const values = this.form.getFieldsValue();
      let { type, status } = values;

      type = type || 0;
      status = status || 0;
      this.filters = { ...this.filters, type, status };

      await this.fetch();
    },

    async fetch() {
      this.loading = true;

      try {
        this.list = await SearchSvc.list(this.filters);

        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    }
  },

  async mounted() {
    const { ok } = await SearchSvc.check();
    if (!ok) {
      this.$message.info("请先开通服务");
      this.$router.replace({ name: "Vip" });
      return;
    }

    await this.fetch();
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-search-list {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .table-wrap {
    background: #fff;

    .ant-table-pagination.ant-pagination {
      float: none;
      text-align: center;
    }
  }
}
</style>
