import request from "./request";

/**
 * 搜一搜
 */

/**
 * 查询
 */
export const list = data => request.get("/search/q", { params: data });

/**
 * 提交页面
 */
export const create = data => request.post("/search/", data);

/**
 * 是否开通服务
 */
export const check = () => request.get("/search/check");
